<template>
  <div>
    <div
      class="flex mt-6 bg-white rounded-lg"
      v-if="Object.keys(catastrophes_naturelles).length"
      id="catasrophesNaturelles"
    >
      <Accordion class="w-full rounded-lg accordion-ipad">
        <AccordionItem>
          <template slot="accordion-trigger">
            <a
              class="flex justify-between items-center w-full py-6 px-3 cursor-pointer sm:px-3"
              @click="activingAccordionInfo(0)"
            >
              <div class="md:w-full flex justify-start items-center space-x-3">
                <CatastrophesNaturellesIcon class="sm:w-36 sm:h-12" />
                <span class="font-bold sm:text-sm">
                  Arrêtés portant reconnaissance de catastrophes naturelles sur
                  la commune
                </span>
              </div>
              <div>
                <ProIcon name="downarrow" v-if="active_accordion_info != 0" />
                <ProIcon name="uparrow" v-else />
              </div>
            </a>
          </template>
          <template slot="accordion-content">
            <div class="p-6 px-6 border-t-2 cursor-auto">
              <div
                v-for="(historiques, name) in catastrophes_naturelles"
                :key="name"
                class="mb-8"
              >
                <div
                  class="flex justify-between items-center sm:items-baseline font-main mb-4"
                >
                  <div class="text-base font-bold">{{ name }} :</div>
                  <div
                    class="text-promy-dark-purple text-5xl sm:text-3xl font-bold"
                  >
                    {{ historiques.length }}
                  </div>
                </div>

                <collapseTable
                  class="hidden lg:flex"
                  :keys="keys_CTN"
                  :items="historiques"
                ></collapseTable>
                <table class="table-auto w-full lg:hidden text-sm">
                  <thead class="bg-promy-gray-225">
                    <tr class="bg-promy-gray-100 rounded-t-box text-left">
                      <th
                        class="rounded-tl-box text-base font-semibold font-main"
                      >
                        Code national CATNAT
                      </th>
                      <th>Début le</th>
                      <th>Fin le</th>
                      <th>Arrêté du</th>
                      <th class="rounded-tr-box">Sur le Journal Officiel du</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(historique, index) in historiques"
                      :key="index"
                      class="font-normal text-base"
                    >
                      <td>
                        {{ historique.codeNatCatnat }}
                      </td>
                      <td>
                        {{ historique.dateDeb }}
                      </td>
                      <td>
                        {{ historique.dateFin }}
                      </td>
                      <td>
                        {{ historique.datePubliArrete }}
                      </td>
                      <td>
                        {{ historique.datePubliJO }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </AccordionItem>
      </Accordion>
    </div>
  </div>
</template>
<script>
import collapseTable from './CollapseTable.vue'
import CatastrophesNaturellesIcon from '../icons/CatastrophesNaturellesIcon.vue'

export default {
  components: {
    collapseTable,
    CatastrophesNaturellesIcon,
  },
  data() {
    return {
      catastrophes_naturelles: {},
      active_accordion: -1,
      active_accordion_info: -1,
      keys_CTN: {
        codeNatCatnat: 'Code national CATNAT',
        dateDeb: 'Début le',
        dateFin: 'Fin le',
        datePubliArrete: 'Arrêté du',
        datePubliJO: 'Sur le Journal Officiel du',
      },
    }
  },
  props: {
    risques: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.risques.catnat) {
      this.catastrophes_naturelles = _.groupBy(
        this.risques.catnat,
        'libRisqueJO',
      )
    }
  },
  methods: {
    activingAccordionInfo(idx) {
      this.active_accordion_info = this.active_accordion_info === idx ? -1 : idx
    },
  },
}
</script>
<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}
</style>
